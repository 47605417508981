footer {
  background-color: #523f3f;
  color: #fff;
  padding: 20px;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  display: inline-block;
  margin-right: 10px;
}

.footer-links li a {
  color: #fff;
  text-decoration: none;
}

.footer-links li a:hover {
  text-decoration: underline;
} */
