@import url("../fonts/fonts.css");

body {
  margin: 0;
  padding: 0;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    rgb(238, 174, 202) 0%,
    rgb(233, 188, 139) 25%,
    rgb(233, 188, 139) 51%,
    #f780ab 100%
  );
  animation: AnimateBG 7s ease infinite;
  z-index: -1;
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.welcome-container {
  width: 40vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .welcome-container {
    width: 100%;
    height: 100vh;
    position: static;
    overflow-x: hidden;
  }
}

@keyframes slide-top-once {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.myName {
  color: #1e1815;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: -1px;
  animation: slide-top-once 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.welcome-to {
  margin-top: -1px;
  margin-bottom: -3px;
  color: white;
  font-weight: 700;
  font-size: 3rem;
  animation: slide-top-once 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.myPhoto {
  margin-top: 5px;
  border-radius: 50%;
  width: 60%;
}

@media (max-width: 768px) {
  .myPhoto {
    width: 80%;
  }
}
