@import url("../fonts/fonts.css");
.home-content {
  background-image: url("/src/components/Welcome/portfolioImage/—Pngtree—abstract\ light\ brown\ background\ with_1576199.png");
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  animation: fadeIn 1.5s ease-in-out;
  background-repeat: no-repeat;
}
.home-text {
  font-size: 1.1rem;
  padding: 20px;
  margin-left: 20px;
  color: #64401f;
  width: 70%;
}
.shape {
  width: 80%;
  border-radius: 1.5rem;
  background-color: #d9b996;
  height: 5px;
}

.contact-button a {
  text-decoration: none;
  color: #64401f;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
