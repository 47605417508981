@import url("../fonts/fonts.css");
nav {
  width: 95%;
  background-color: #c99a5b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  height: fit-content;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 2;
}
nav ul {
  display: flex;
  list-style: none;
  padding-right: 30px;
}
nav ul li .btn {
  color: #1e1815;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
  padding: 10px;
  border: none;
  background-color: #c99a5b;
}
nav ul li .btn:hover {
  color: white;
  cursor: pointer;
}
.btn:hover {
  background-color: #64401f;
  border-radius: 1.5rem;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.download {
  color: #1e1815;
  text-decoration: none;
}
.download:hover {
  color: white;
}
