@import url("../fonts/fonts.css");
.contact-container {
  height: fit-content;
  background-color: #be9e7c;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.contact-title {
  font-weight: 600;
  font-size: 2rem;
  color: #64401f;
}
.biggerText {
  font-weight: 400;
  font-size: 0.9rem;
  color: white;
  margin: 20px;
  text-align: center;
}
.contact-list {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #64401f;
}
.list-item {
  list-style: none;
  padding: 10px;
}
.list-item a {
  color: #64401f;
  list-style: none;
  transition: transform 0.3s ease;
}
.iconStyle {
  padding: 5px;
}
.iconStyle:hover {
  transform: scale(1.2) rotate(20deg) translateX(5px) translateY(-5px);
}
