.about-container {
  height: fit-content;
  background-image: url("/src/components/Welcome/portfolioImage/WhatsApp\ Image\ 2023-05-16\ at\ 13.41.30.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}
.text-about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
.about-title {
  margin-top: 40px;
  font-weight: 800;
  color: beige;
  font-size: 3.5rem;
  text-align: center;
}
.about-text {
  width: 40%;
  text-align: justify;
  font-size: 0.9rem;
  margin: 20px;
  color: wheat;
}
.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.skills-text {
  font-size: 0.9rem;
  margin: 15px 0;
  color: beige;
  line-height: 1.6;
  max-width: 70%;
  border: 1px solid beige;
  padding: 10px;
  border-radius: 8px;
}

.skills-text .material-icons {
  margin-right: 8px;
  color: beige;
  font-size: 1rem;
}
@media (max-width: 768px) {
  .about-container {
    padding: 20px;
  }

  .text-about-container {
    margin-top: 20px;
  }

  .about-title {
    margin-top: 20px;
    font-size: 2.5rem;
  }

  .about-text {
    width: 80%;
    font-size: 0.7rem;
    margin: 10px;
  }
}
