@import url("../fonts/fonts.css");

.title-project {
  margin-top: -10px;
  font-size: 24px;
  margin-bottom: 30px;
  background-color: #d2b48c;
  padding: 10px 20px;
  color: #fff;
}
.projects-first {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.big-container {
  background-color: #f4e8d9;
  padding-bottom: 30px;
}

.project-img {
  width: 300px;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.explanation {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

.img-href {
  text-decoration: none;
  color: inherit;
}

.image-overlay {
  position: relative;
  border-radius: 8px;
}

.project-img {
  transition: transform 0.3s ease;
}

.image-overlay:hover .project-img {
  transform: scale(1.05);
}

.image-text {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(66, 28, 28, 0.6);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  height: 100%;
  width: 100%;
  margin: 5px;
}

@media (max-width: 768px) {
  .projects-first {
    margin-right: 0;
  }

  .project-img {
    width: 80%;
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .image-overlay:hover .project-img {
    transform: none;
  }
}
.txt {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: rgb(78, 66, 38);
}
a {
  text-decoration: none;
}
